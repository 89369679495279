import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import scoring_logo from "../../images/scoring_logo.png"
import { Link } from "gatsby"
import { colors } from "../../constants/constants"
function HeroSection() {
  return (
    <section>
      <div
        style={{ backgroundColor: "#FFFEFF" }}
        className="flex gap-4 flex-col text-center md:text-left md:flex-row items-center"
      >
        <img src={scoring_logo}></img>
        {/* <StaticImage
          src="../images/scoring_logo.png"
          width={300}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="A Gatsby astronaut"
          style={{ marginBottom: `1.45rem` }}
        /> */}
        <div>
          <div>
            <h1 className="text-scoringLightBlue">
              <span className="text-scoringBlue">S</span>mart{" "}
              <span className="text-scoringBlue">C</span>ollaborative c
              <span className="text-scoringBlue">O</span>
              mputing, caching and netwo
              <span className="text-scoringBlue">R</span>king parad
              <span className="text-scoringBlue">I</span>gm for{" "}
              <span className="text-scoringBlue">N</span>ext{" "}
              <span className="text-scoringBlue">G</span>
              eneration communication infrastructures
            </h1>
            {/* <div>
            <button className="p-2 bg-blue-600 rounded-md text-white">
              <a href="https://www.chistera.eu/projects/scoring">See project</a>
            </button>
          </div> */}
            <p className="font-semibold">Future compute-centric networks</p>
          </div>
          <div>
            <button className="rounded-md bg-scoringLightBlue text-white p-2 font-semibold">
              <Link
                className="text-sm"
                to="https://www.chistera.eu/projects/scoring"
              >
                See Project Abstract
              </Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
