import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ProjectOverview = () => (
  <section id="project">
    <div className="flex mt-20 flex-col">
      <div className="flex justify-center">
        <h1 className="text-scoringBlue">Overview</h1>
      </div>

      <div className="flex-col md:flex-row flex w-full justify-between mt-10 items-center md:items-stretch">
        <div className=" mt-5 md:mt-0 w-11/12 md:w-1/3 md:mr-2 flex flex-col">
          <h2
            style={{
              textUnderlineOffset: "12px",
            }}
            className="text-center underline decoration-blue-700 decoration-4"
          >
            Join Us on Twitter
          </h2>
          <div className="bg-slate-100 rounded-md p-1 mt-4 flex-1">
            <div
              className="overflow-y-scroll"
              style={{
                maxHeight: "420px",
              }}
            >
              <a
                className="twitter-timeline"
                href="https://twitter.com/scoring_chist?ref_src=twsrc%5Etfw"
              >
                Tweets by scoring_chist
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              ></script>
            </div>
          </div>
        </div>

        <div className="mt-5 md:mt-0 w-11/12 md:w-1/3 mr-2 flex flex-col">
          <h2
            style={{
              textUnderlineOffset: "12px",
            }}
            className="text-center underline decoration-blue-700 decoration-4"
          >
            Project Label
          </h2>
          <div className="bg-slate-100 rounded-md p-1 mt-4 flex-1">
            <a href="#">
              <StaticImage
                src="../../images/Chistera-logo.png"
                alt="Chist-era"
                className="rounded-t-lg h-44 w-full object-center"
              />
            </a>
            <div class="p-5 flex-1">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  Chist-Era
                </h5>
              </a>
              <p class="mb-3 font-light text-xs ">
                CHIST-ERA is a coordination and co-operation activity of
                national and regional research funding organisations mainly in
                Europe and is supported by the Horizon 2020 Future and Emerging
                Technologies (FET) programme of the European Union through the
                ERA-NET Cofund funding scheme
              </p>
              <a
                href="https://www.chistera.eu/"
                class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-scoringLightBlue rounded-lg hover:bg-scoringBlue focus:ring-4 focus:outline-none focus:ring-blue-300 "
              >
                Read more
                <svg
                  class="ml-2 -mr-1 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5 md:mt-0 w-11/12 md:w-1/3">
          <div className="h-full flex flex-col">
            <h2
              style={{
                textUnderlineOffset: "12px",
              }}
              className="text-center underline decoration-blue-700 decoration-4"
            >
              Project Highlights
            </h2>
            <div className="bg-slate-100 rounded-md p-3 pt-2 mt-4 flex-1 text-xs">
              <h4 className="mb-2">Future events</h4>
              <ul className="ml-0 list-disc">
                <li className="ml-2">
                  - <b>Chist-Era</b> Project Seminar 28-30 March 2022, Online
                </li>
                <li className="ml-2">
                  - <b>IEEE ICC 2022</b>: IEEE International Conference on
                  Communications 2022, 16-20 May.
                </li>
              </ul>
              <h4 className="mb-2">Past events</h4>
              <ul>
                <li className="ml-2">
                  - Global Communications Conference, <b>GLOBECOM</b>, 7–11 Dec
                  2021.
                </li>
                <li className="ml-2">
                  - IEEE 93rd Vehicular Technology Conference,{" "}
                  <b>VTC2021-Spring</b>, 25-28 april 2021.
                </li>
                <li className="ml-2">
                  - 7th International Symposium on Ubiquitous Networking,{" "}
                  <b>UNet 2021</b>, May 19-22, 2021
                </li>
                <li className="ml-2">
                  - <b>Chist-Era Project Seminar</b> 12-14 April, 2021
                </li>
                <li className="ml-2">
                  - Global Communications Conference, <b>GLOBECOM</b>, 7–11 Dec
                  2020.
                </li>
                <li className="ml-2">
                  - 16th International Conference on Network and Service
                  Management <b>CNSM</b> 2020, 2-6 November 2020.
                </li>
              </ul>
              <h4 className="mb-2">Weekly meetings</h4>
              <ul>
                <li className="ml-2">
                  - Every Friday at 3:00pm <b>CET</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ProjectOverview
