import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import l3i from "../../images/univs/l3i.png"
import concordia from "../../images/univs/concordia.png"
import uqam from "../../images/univs/uqam.jpg"
import sabanci from "../../images/univs/sabanci.png"

function Consortium() {
  return (
    <div className="mt-10">
      <h1 className="text-center text-scoringBlue">Partners</h1>
      <ul className="flex flex-col md:flex-row items-center justify-between">
        <li>
          <a href="https://l3i.univ-larochelle.fr/" target="_blank">
            <img
              width="300px"
              src={l3i}
              alt="L3i La Rochelle"
              className=" w-52 object-contain z-0"
            />
          </a>
        </li>
        <li>
          <a href="https://www.concordia.ca/" target="_blank">
            <img
              src={concordia}
              alt="Concordia"
              className="w-52 object-contain z-0"
            />
          </a>
        </li>
        <li>
          <a href="https://www.sabanciuniv.edu" target="_blank">
            <img
              src={sabanci}
              alt="Sabanci"
              className="w-52 object-contain z-0"
            />
          </a>
        </li>
        <li>
          <a href="https://uqam.ca/" target="_blank">
            <img src={uqam} alt="UQAM" className="w-52 object-contain z-0" />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Consortium
