import * as React from "react"
import Layout from "../components/layout"
import HeroSection from "../components/HeroSection"
import ProjectOverview from "../components/ProjectOverview"
import Consortium from "../components/HeroSection/consurtium"
const IndexPage = ({ location }) => (
  <Layout pagePath={location.pathname}>
    <HeroSection></HeroSection>
    <Consortium />
    <ProjectOverview />
  </Layout>
)

export default IndexPage
